<template>
  <div>
    <nav class="navbar navbar-expand-sm navbar-white bg-white">
      <div class="container-fluid">
        <button
          class="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#mynavbar"
        >
          <span class="navbar-toggler-icon"></span>
        </button>
        <div class="collapse navbar-collapse" id="mynavbar">
          <ul class="navbar-nav mx-auto justify-content-center">
            <li class="nav-item">
              <a class="nav-link" @click="ir('home')"><strong>Inicio</strong></a>
            </li>
            <li class="nav-item">
              <a class="nav-link" @click="ir('servicios')"><strong>Servicios</strong></a>
            </li>
            <li class="nav-item">
              <a class="nav-link" @click="ir('cursosyseminarios')"><strong>Cursos & Seminarios</strong></a>
            </li>
            <li>
              <a class="imagencabecera" @click="ir('home')"><img src="@/assets/logos/logo-rojo.png" alt="" class="logo"></a>
            </li>
            <li class="nav-item">
              <a class="nav-link" @click="ir('bienestarysalud')"><strong>Bienestar & Salud</strong></a>
            </li>
            <li class="nav-item">
              <a class="nav-link" @click="ir('quienessomos')"><strong>Quienes Somos</strong></a>
            </li>
            <li class="nav-item">
              <a class="nav-link" @click="ir('contacto')"><strong>Contacto</strong></a>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  </div>
</template>

<script>
export default {
  name: "HeaderComponent",
  data() {
    return {};
  },
  methods: {
    ir(route) {
      this.$router.push({
        name: route,
      });
    },
  },
};
</script>

<style scoped>
.nav-link:hover {
  color: #ff0000; /* Cambia el color del texto al pasar el ratón */
  background-color: rgba(255, 255, 255, 0.1); /* Cambia el color de fondo al pasar el ratón */
  transition: background-color 0.3s ease, color 0.3s ease; /* Agrega una transición suave */
}
.logo {
  width: 40px;
}
.logo:hover {
  image-rendering: url("@/assets/logos/logo-azul.png");
}
</style>
