<template>
  <div class="home">
    <!-- Slider principal -->

    <div
      id="carouselExampleIndicators"
      class="carousel slide"
      data-bs-ride="carousel"
    >
      <div class="carousel-indicators">
        <button
          type="button"
          data-bs-target="#carouselExampleIndicators"
          data-bs-slide-to="0"
          class="active"
          aria-current="true"
          aria-label="Slide 1"
        ></button>
        <button
          type="button"
          data-bs-target="#carouselExampleIndicators"
          data-bs-slide-to="1"
          aria-label="Slide 2"
        ></button>
        <button
          type="button"
          data-bs-target="#carouselExampleIndicators"
          data-bs-slide-to="2"
          aria-label="Slide 3"
        ></button>
        <button
          type="button"
          data-bs-target="#carouselExampleIndicators"
          data-bs-slide-to="3"
          aria-label="Slide 4"
        ></button>
        <button
          type="button"
          data-bs-target="#carouselExampleIndicators"
          data-bs-slide-to="4"
          aria-label="Slide 5"
        ></button>
      </div>
      <div class="carousel-inner">
        <div class="carousel-item contenedor active">
          <img
            src="@/assets/home/Slider-01.jpg"
            class="d-block w-100"
            alt="..."
          />
        </div>
        <div class="carousel-item contenedor">
          <img
            @click="ir('servicios')"
            src="@/assets/home/Slider-02.png"
            class="d-block w-100"
            alt="..."
          />
        </div>
        <div class="carousel-item contenedor">
          <img
            @click="ir('cursosyseminarios')"
            src="@/assets/home/Slider-03.png"
            class="d-block w-100"
            alt="..."
          />
        </div>
        <div class="carousel-item contenedor">
          <img
            @click="ir('bienestarysalud')"
            src="@/assets/home/Slider-04.png"
            class="d-block w-100"
            alt="..."
          />
        </div>
        <div class="carousel-item contenedor">
          <img
            @click="ir('quienessomos')"
            src="@/assets/home/Slider-05.gif"
            class="d-block w-100"
            alt="..."
          />
        </div>
      </div>
      <button
        class="carousel-control-prev"
        type="button"
        data-bs-target="#carouselExampleIndicators"
        data-bs-slide="prev"
      >
        <span class="carousel-control-prev-icon" aria-hidden="true"></span>
        <span class="visually-hidden">Previous</span>
      </button>
      <button
        class="carousel-control-next"
        type="button"
        data-bs-target="#carouselExampleIndicators"
        data-bs-slide="next"
      >
        <span class="carousel-control-next-icon" aria-hidden="true"></span>
        <span class="visually-hidden">Next</span>
      </button>
    </div>

    <!-- texto rojo -->

    <div class="container contenedortexto">
      <div class="row">
        <div class="col-12">
          <p class="titulorojo">
            <strong>Adiestramiento Canino en Talavera</strong>
          </p>
        </div>
        <div class="col-6">
          <p class="textoizq">
            <strong
              >Amado Amo adiestramiento somos un equipo de adiestradores
              profesionales con una misión:</strong
            >
          </p>
        </div>
        <div class="col-6">
          <p>
            Ayudarte a entender mejor a tu perro y enseñaros a ser equipo
            trabajando, disfrutando y aprendiendo juntos.
            <strong class="textodrcha"
              >DOS es una manera de avanzar JUNTOS</strong
            >, DOS es saber que ya nunca caminarás solo. Porque cuando tienes un
            perro ya no vuelves a ser uno…
            <strong class="textodrcha">Sois DOS.</strong>
          </p>
        </div>
      </div>
    </div>

    <!-- foto 4 apartados -->

    <div class="container">
      <div class="row">
        <div class="col-12">
          <img class="foto-4" src="@/assets/home/animacion-home.gif" alt="" />
        </div>
      </div>
    </div>

    <!-- donde trabajamos -->

    <div class="dondetrabajamos">
      <div class="container">
        <div class="row">
          <div class="col-12">
            <h1 class="donde-trabajamos">Donde trabajamos</h1>
          </div>
          <div class="container">
            <div class="row">
              <div class="col-3 contenedorimagen">
                <img
                  class="imagen-sitio"
                  src="@/assets/sitio-home.png"
                  alt=""
                />
              </div>
              <div class="col-3 contenedorimagen">
                <img
                  class="imagen-sitio"
                  src="@/assets/sitio-home.png"
                  alt=""
                />
              </div>
              <div class="col-3 contenedorimagen">
                <img
                  class="imagen-sitio"
                  src="@/assets/sitio-home.png"
                  alt=""
                />
              </div>
              <div class="col-3 contenedorimagen">
                <img
                  class="imagen-sitio"
                  src="@/assets/sitio-home.png"
                  alt=""
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "HomeView",
  data() {},
  mounted() {},
  methods: {
    ir(route) {
      this.$router.push({
        name: route,
      });
    },
  },
};
</script>

<style scoped>
.dondetrabajamos {
  margin-top: 70px;
  background-image: url("@/assets/home/cabecera-sitios.png");
  background-repeat: no-repeat;
  background-size: contain;
}

.home {
  cursor: url("@/assets/cursor-01-40.png"), auto;
}
.home:active {
  cursor: url("@/assets/cursor-01-40.png"), auto;
}
.home:hover {
  cursor: url("@/assets/cursor-01-40.png"), auto;
}
html,
body {
  height: 100%;
}

body {
  align-items: center;
  background-color: #d4e6ff;
  display: flex;
  justify-content: center;
  margin: 0;
}

/* Texto rojo */

.contenedortexto {
  margin-bottom: 40px;
}

.titulorojo {
  margin-top: 40px;
  font-size: 20px;
  font-weight: bold;
  color: #333333;
  line-height: 30px;
}

.textoizq {
  font-family: "Arial";
  font-weight: 600;
  color: crimson;
  font-size: 32px;
  line-height: 34px;
}

.textodrcha {
  color: crimson;
}

/* Foto 4 partes */

.foto-4 {
  width: 100%;
}

/* Donde trabajamos */

.donde-trabajamos {
  text-align: center;
  margin-top: 40px;
  margin-bottom: 40px;
}

.contenedorimagen {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 40px;
}

.imagen-sitio {
  width: 50%;
}

/* Parallax */

main {
  height: 100vh;
  overflow-x: hidden;
  overflow-y: auto;
  perspective: 2px;
}

section {
  transform-style: preserve-3d;
  position: relative;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
}

section h1 {
  text-align: center;
  font-size: 4rem;
  font-family: sans-serif;
}

.no-parallax {
  background-color: #5333ed;
  z-index: 999;
}

.parallax h1 {
  width: 60%;
  font-size: 2rem;
}

.parallax::after {
  content: " ";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  transform: translateZ(-1px) scale(1.5);
  background-size: 100%;
  z-index: -1;
}

.bg::after {
  background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
    url("@/assets/foto_01.jpg");
  background-size: cover;
}
</style>
