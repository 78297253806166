<template>
  <div>
    <!-- Foto principal -->

    <img src="@/assets/cursosyseminarios/Slider-03.png" alt="" class="fotoprincipal" />
  </div>
</template>

<script>
export default {
  name: "CursosSeminariosView",
  data() {},
  components: {},
  methods: {},
};
</script>

<style scoped>
.fotoprincipal {
  width: 100%;
}
</style>
