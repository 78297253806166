<template>
  <HeaderComponent v-if="!this.$route.meta.hideHeader"></HeaderComponent>
  <router-view></router-view>
  <WhatsappButtonComponent v-if="!this.$route.meta.hideWhatsapp"></WhatsappButtonComponent>
  <FooterView v-if="!this.$route.meta.hideFoooter"></FooterView>
</template>

<script>
import HeaderComponent from './components/HeaderComponent.vue'
import FooterView from './components/FooterView.vue'
import WhatsappButtonComponent from './components/WhatsappButtonComponent.vue'

export default {
  name: 'app',
  components: {
    HeaderComponent,
    FooterView,
    WhatsappButtonComponent,
  }
}
</script>

<style lang="scss">
body {
  background-color: rgb(255, 255, 255);
}
</style>

<style scoped>
* {
  margin: 0;
  padding: 0;
  cursor: url("@/assets/cursor-01-40.png"), auto;
}
*:active {
  cursor: url("@/assets/cursor-01-40.png"), auto;
}
*:hover {
  cursor: url("@/assets/cursor-01-40.png"), auto;
}
</style>
