<template>
  <div class="contacto-view">
    <div class="container">
      <div class="row">
        <div class="col-12 col-sm-6">
          <div class="contact-info">
            <h2>Datos de Contacto</h2>
            <p>
              <strong>Correo Electrónico:</strong> info@adiestramientocanino.com
            </p>
            <p><strong>Teléfono:</strong> +34 644 780 159</p>
          </div>
        </div>
        <div class="col-12 col-sm-6">
          <h2 class="mb-4">Horarios de Atención</h2>
          <ul class="list-unstyled">
            <li><strong>Lunes a Viernes:</strong> 9:00 am - 6:00 pm</li>
            <li><strong>Sábado:</strong> 10:00 am - 4:00 pm</li>
            <li><strong>Domingo:</strong> Cerrado</li>
          </ul>
        </div>

        <div class="container segundo-container">
          <div class="row">
            <div class="col-12 col-sm-6">
              <h2 class="mb-4">Contáctanos</h2>
              <form @submit.prevent="submitForm">
                <div class="form-group">
                  <input
                    type="text"
                    class="form-control"
                    placeholder="Nombre"
                    v-model="formData.nombre"
                    required
                  />
                </div>
                <div class="form-group">
                  <input
                    type="email"
                    class="form-control"
                    placeholder="Correo Electrónico"
                    v-model="formData.email"
                    required
                  />
                </div>
                <div class="form-group">
                  <textarea
                    class="form-control"
                    placeholder="Mensaje"
                    rows="5"
                    v-model="formData.mensaje"
                    required
                  ></textarea>
                </div>
                <button type="submit" class="btn btn-primary btn-block">
                  Enviar Mensaje
                </button>
              </form>
            </div>

            <div class="col-12 col-sm-6">
              <h2>Ubicación</h2>
              <p>Puedes encontrarnos en:</p>
              <p>Calle Principal, Ciudad, País</p>
              <div class="embed-responsive embed-responsive-16by9">
                <iframe
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d642.8696258343252!2d-4.829308024864587!3d39.96329364824813!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0xd401bc573264567%3A0xec73901c13ccb9e!2sAmado%20Amo%20Adiestramiento!5e0!3m2!1ses!2ses!4v1714490795235!5m2!1ses!2ses"
                  width="600"
                  height="450"
                  style="border: 0"
                  allowfullscreen=""
                  loading="lazy"
                  referrerpolicy="no-referrer-when-downgrade"
                ></iframe>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ContactoView",
  data() {
    return {
      formData: {
        nombre: "",
        email: "",
        mensaje: "",
      },
    };
  },
  methods: {
    submitForm() {
      console.log("Formulario enviado:", this.formData);
      this.formData.nombre = "";
      this.formData.email = "";
      this.formData.mensaje = "";
      // Aquí puedes agregar lógica para enviar el formulario
    },
  },
};
</script>

<style scoped>
.segundo-container {
  margin-top: 50px;
}

.contacto-view {
  padding-top: 80px;
  padding-bottom: 80px;
}

.contacto-view h2 {
  font-size: 24px;
  font-weight: bold;
}

.contacto-view form .form-control {
  border-radius: 0;
}

.contacto-view form button.btn-primary {
  border-radius: 0;
}

.contacto-view .list-unstyled li {
  font-size: 16px;
}

.contacto-view iframe {
  border: 1px solid #ddd;
  border-radius: 5px;
}

.contact-info p {
  font-size: 18px;
  margin-bottom: 10px;
}
</style>
