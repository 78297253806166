<template>
  <div>
      <!-- Foto principal -->

      <img src="@/assets/quienessomos/Slider-05.gif" alt="" class="fotoprincipal">

      <!-- Texto info -->

    <div class="container contenedortexto">
      <div class="row">
        <div class="col-12">
          <p class="titulorojo">
            <strong>Adiestramiento Canino en Talavera</strong>
          </p>
        </div>
        <div class="col-6">
          <p class="textoizq">
            <strong
              >Amado Amo adiestramiento somos un equipo de adiestradores
              profesionales con una misión:</strong
            >
          </p>
        </div>
        <div class="col-6">
          <p>
            Ayudarte a entender mejor a tu perro y enseñaros a ser equipo
            trabajando, disfrutando y aprendiendo juntos.
            <strong class="textodrcha"
              >DOS es una manera de avanzar JUNTOS</strong
            >, DOS es saber que ya nunca caminarás solo. Porque cuando tienes un
            perro ya no vuelves a ser uno…
            <strong class="textodrcha">Sois DOS.</strong>
          </p>
        </div>
      </div>
    </div>


  </div>
</template>

<script>
export default {
  name: 'QuienesSomosView',
  data() {},
  components: {},
  methods: {},
}
</script>

<style scoped>

/* Foto principal */

.fotoprincipal {
  width: 100%;
}

/* Texto info */

.contenedortexto {
  margin-bottom: 40px;
}

.titulorojo {
  margin-top: 40px;
 font-size: 20px;
 font-weight: bold;
 color: #333333;
 line-height: 30px;
}

.textoizq {
  font-family: 'Arial';
  font-weight: 600;
  color: crimson;
  font-size: 32px;
  line-height: 34px;
}

.textodrcha {
  color: crimson;
}
</style>
