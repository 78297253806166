<template>
  <div>
    <!-- Foto principal -->

    <img src="@/assets/servicios/Slider-02.png" alt="" class="fotoprincipal" />

    <!-- Texto info -->

    <div class="container contenedortexto">
      <div class="row">
        <div class="col-12">
          <p class="titulorojo">
            <strong>Adiestramiento Canino en Talavera</strong>
          </p>
        </div>
        <div class="col-6">
          <p class="textoizq">
            <strong
              >Amado Amo adiestramiento somos un equipo de adiestradores
              profesionales con una misión:</strong
            >
          </p>
        </div>
        <div class="col-6">
          <p>
            Ayudarte a entender mejor a tu perro y enseñaros a ser equipo
            trabajando, disfrutando y aprendiendo juntos.
            <strong class="textodrcha"
              >DOS es una manera de avanzar JUNTOS</strong
            >, DOS es saber que ya nunca caminarás solo. Porque cuando tienes un
            perro ya no vuelves a ser uno…
            <strong class="textodrcha">Sois DOS.</strong>
          </p>
        </div>
      </div>
    </div>

    <!-- Slider adiestramiento-->

    <div
      id="carouselExampleIndicators0"
      class="carousel slide"
      data-bs-ride="carousel"
    >
      <div class="carousel-indicators">
        <button
          type="button"
          data-bs-target="#carouselExampleIndicators0"
          data-bs-slide-to="0"
          class="active"
          aria-current="true"
          aria-label="Slide 1"
        ></button>
        <button
          type="button"
          data-bs-target="#carouselExampleIndicators0"
          data-bs-slide-to="1"
          aria-label="Slide 2"
        ></button>
      </div>
      <div class="carousel-inner">
        <div class="carousel-item contenedor active">
          <img
            src="@/assets/foto_03.jpg"
            class="d-block w-100 imagen1"
            alt="..."
          />
        </div>
        <div class="carousel-item contenedor">
          <img
            src="@/assets/foto_03.jpg"
            class="d-block w-100 imagen2"
            alt="..."
          />
        </div>
      </div>
      <button
        class="carousel-control-prev"
        type="button"
        data-bs-target="#carouselExampleIndicators0"
        data-bs-slide="prev"
      >
        <span class="carousel-control-prev-icon" aria-hidden="true"></span>
        <span class="visually-hidden">Previous</span>
      </button>
      <button
        class="carousel-control-next"
        type="button"
        data-bs-target="#carouselExampleIndicators0"
        data-bs-slide="next"
      >
        <span class="carousel-control-next-icon" aria-hidden="true"></span>
        <span class="visually-hidden">Next</span>
      </button>
    </div>

    <!-- Texto info -->

    <div class="container contenedortexto">
      <div class="row">
        <div class="col-12">
          <p class="titulorojo">
            <strong>Adiestramiento Canino en Talavera</strong>
          </p>
        </div>
        <div class="col-6">
          <p class="textoizq">
            <strong
              >Amado Amo adiestramiento somos un equipo de adiestradores
              profesionales con una misión:</strong
            >
          </p>
        </div>
        <div class="col-6">
          <p>
            Ayudarte a entender mejor a tu perro y enseñaros a ser equipo
            trabajando, disfrutando y aprendiendo juntos.
            <strong class="textodrcha"
              >DOS es una manera de avanzar JUNTOS</strong
            >, DOS es saber que ya nunca caminarás solo. Porque cuando tienes un
            perro ya no vuelves a ser uno…
            <strong class="textodrcha">Sois DOS.</strong>
          </p>
        </div>
      </div>
    </div>

    <!-- Slider mod. conducta -->

    <div
      id="carouselExampleIndicators1"
      class="carousel slide"
      data-bs-ride="carousel"
    >
      <div class="carousel-indicators">
        <button
          type="button"
          data-bs-target="#carouselExampleIndicators1"
          data-bs-slide-to="0"
          class="active"
          aria-current="true"
          aria-label="Slide 1"
        ></button>
        <button
          type="button"
          data-bs-target="#carouselExampleIndicators1"
          data-bs-slide-to="1"
          aria-label="Slide 2"
        ></button>
      </div>
      <div class="carousel-inner">
        <div class="carousel-item contenedor active">
          <img
            src="@/assets/foto_03.jpg"
            class="d-block w-100 imagen1"
            alt="..."
          />
        </div>
        <div class="carousel-item contenedor">
          <img
            src="@/assets/foto_03.jpg"
            class="d-block w-100 imagen2"
            alt="..."
          />
        </div>
      </div>
      <button
        class="carousel-control-prev"
        type="button"
        data-bs-target="#carouselExampleIndicators1"
        data-bs-slide="prev"
      >
        <span class="carousel-control-prev-icon" aria-hidden="true"></span>
        <span class="visually-hidden">Previous</span>
      </button>
      <button
        class="carousel-control-next"
        type="button"
        data-bs-target="#carouselExampleIndicators1"
        data-bs-slide="next"
      >
        <span class="carousel-control-next-icon" aria-hidden="true"></span>
        <span class="visually-hidden">Next</span>
      </button>
    </div>

    <!-- Texto info -->

    <div class="container contenedortexto">
      <div class="row">
        <div class="col-12">
          <p class="titulorojo">
            <strong>Adiestramiento Canino en Talavera</strong>
          </p>
        </div>
        <div class="col-6">
          <p class="textoizq">
            <strong
              >Amado Amo adiestramiento somos un equipo de adiestradores
              profesionales con una misión:</strong
            >
          </p>
        </div>
        <div class="col-6">
          <p>
            Ayudarte a entender mejor a tu perro y enseñaros a ser equipo
            trabajando, disfrutando y aprendiendo juntos.
            <strong class="textodrcha"
              >DOS es una manera de avanzar JUNTOS</strong
            >, DOS es saber que ya nunca caminarás solo. Porque cuando tienes un
            perro ya no vuelves a ser uno…
            <strong class="textodrcha">Sois DOS.</strong>
          </p>
        </div>
      </div>
    </div>

    <!-- Slider clases grupales -->

    <div
      id="carouselExampleIndicators2"
      class="carousel slide"
      data-bs-ride="carousel"
    >
      <div class="carousel-indicators">
        <button
          type="button"
          data-bs-target="#carouselExampleIndicators2"
          data-bs-slide-to="0"
          class="active"
          aria-current="true"
          aria-label="Slide 1"
        ></button>
        <button
          type="button"
          data-bs-target="#carouselExampleIndicators2"
          data-bs-slide-to="1"
          aria-label="Slide 2"
        ></button>
      </div>
      <div class="carousel-inner">
        <div class="carousel-item contenedor active">
          <img
            src="@/assets/foto_03.jpg"
            class="d-block w-100 imagen1"
            alt="..."
          />
        </div>
        <div class="carousel-item contenedor">
          <img
            src="@/assets/foto_03.jpg"
            class="d-block w-100 imagen2"
            alt="..."
          />
        </div>
      </div>
      <button
        class="carousel-control-prev"
        type="button"
        data-bs-target="#carouselExampleIndicators2"
        data-bs-slide="prev"
      >
        <span class="carousel-control-prev-icon" aria-hidden="true"></span>
        <span class="visually-hidden">Previous</span>
      </button>
      <button
        class="carousel-control-next"
        type="button"
        data-bs-target="#carouselExampleIndicators2"
        data-bs-slide="next"
      >
        <span class="carousel-control-next-icon" aria-hidden="true"></span>
        <span class="visually-hidden">Next</span>
      </button>
    </div>

    <!-- Texto info -->

    <div class="container contenedortexto">
      <div class="row">
        <div class="col-12">
          <p class="titulorojo">
            <strong>Adiestramiento Canino en Talavera</strong>
          </p>
        </div>
        <div class="col-6">
          <p class="textoizq">
            <strong
              >Amado Amo adiestramiento somos un equipo de adiestradores
              profesionales con una misión:</strong
            >
          </p>
        </div>
        <div class="col-6">
          <p>
            Ayudarte a entender mejor a tu perro y enseñaros a ser equipo
            trabajando, disfrutando y aprendiendo juntos.
            <strong class="textodrcha"
              >DOS es una manera de avanzar JUNTOS</strong
            >, DOS es saber que ya nunca caminarás solo. Porque cuando tienes un
            perro ya no vuelves a ser uno…
            <strong class="textodrcha">Sois DOS.</strong>
          </p>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
export default {
  name: "ServiciosView",
  data() {},
  components: {},
  methods: {},
};
</script>

<style scoped>
/* Foto principal */

.fotoprincipal {
  width: 100%;
}

/* Texto info */

.contenedortexto {
  margin-bottom: 40px;
}

.titulorojo {
  margin-top: 40px;
  font-size: 20px;
  font-weight: bold;
  color: #333333;
  line-height: 30px;
}

.textoizq {
  font-family: "Arial";
  font-weight: 600;
  color: crimson;
  font-size: 32px;
  line-height: 34px;
}

.textodrcha {
  color: crimson;
}

/* Sliders */

/* Texto sliders */
</style>
