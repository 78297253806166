import { createRouter, createWebHashHistory } from 'vue-router'
import HomeView from '@/views/HomeView.vue';
import ServiciosView from '@/views/ServiciosView.vue';
import CursosSeminariosView from '@/views/CursosSeminariosView.vue';
import QuienesSomosView from '@/views/QuienesSomosView.vue';
import BienestarYSaludView from '@/views/BienestarYSaludView.vue';
import ContactoView from '@/views/ContactoView.vue';

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView,
    meta: {
      hideHeader: false,
      hideFooter: false,
      hideWhatsapp: false,
    },
  },
  {
    path: '/servicios',
    name: 'servicios',
    component: ServiciosView,
    meta: {
      hideHeader: false,
      hideFooter: false,
      hideWhatsapp: false,
    },
  },
  {
    path: '/cursos-y-seminarios',
    name: 'cursosyseminarios',
    component: CursosSeminariosView,
    meta: {
      hideHeader: false,
      hideFooter: false,
      hideWhatsapp: false,
    },
  },
  {
    path: '/bienestar-y-salud',
    name: 'bienestarysalud',
    component: BienestarYSaludView,
    meta: {
      hideHeader: false,
      hideFooter: false,
      hideWhatsapp: false,
    },
  },
  {
    path: '/quienes-somos',
    name: 'quienessomos',
    component: QuienesSomosView,
    meta: {
      hideHeader: false,
      hideFooter: false,
      hideWhatsapp: false,
    },
  },
  {
    path: '/contacto',
    name: 'contacto',
    component: ContactoView,
    meta: {
      hideHeader: false,
      hideFooter: false,
      hideWhatsapp: false,
    },
  },
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

export default router
