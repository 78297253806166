<template>
  <div class="footer">
    <div class="container">
      <div class="row">
        <div class="col-md-3 d-flex flex-column align-items-center">
          <img class="logo" src="@/assets/logos/logo-rojo.png" alt="Amado Amo Adiestramiento">
          <h5 class="company-name">Amado Amo Adiestramiento</h5>
        </div>
        <div class="col-md-3">
          <h4>Explorar</h4>
          <ul class="footer-links">
            <li><a class="link" @click="ir('home')">Inicio</a></li>
            <li><a class="link" @click="ir('servicios')">Servicios</a></li>
            <li><a class="link" @click="ir('cursosyseminarios')">Cursos & Seminarios</a></li>
            <li><a class="link" @click="ir('bienestarysalud')">Bienestar & Salud</a></li>
            <li><a class="link" @click="ir('quienessomos')">Quiénes Somos</a></li>
            <li><a class="link" @click="ir('contacto')">Contacto</a></li>
          </ul>
        </div>
        <div class="col-md-3">
          <h4>Horarios</h4>
          <p><strong>De lunes a viernes:</strong><br>9:00 am - 6:00 pm</p>
          <p><strong>Sábados:</strong><br>10:00 am - 4:00 pm</p>
          <p><strong>Domingos y festivos:</strong><br>Cerrado</p>
        </div>
        <div class="col-md-3">
          <h4>Síguenos</h4>
          <ul class="social-links">
            <li><a href="https://www.facebook.com/amadoamoadiestramiento/" target="_blank"><img class="icono-red" src="@/assets/iconos/icono-facebook.png" alt=""></a></li>
            <li><a href="https://www.instagram.com/amadoamoadiestramiento/" target="_blank"><img class="icono-red" src="@/assets/iconos/icono-instagram.png" alt=""></a></li>
            <li><a href="https://www.tiktok.com/@amadoamo.educa" target="_blank"><img class="icono-red" src="@/assets/iconos/icono-tik-tok.png" alt=""></a></li>
            <li><a href="https://www.youtube.com/@amadoamo2680" target="_blank"><img class="icono-red" src="@/assets/iconos/icono-youtube.png" alt=""></a></li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "FooterView",
  data() {
    return {};
  },
  methods: {
    ir(route) {
      this.$router.push({
        name: route,
      });
    },
  },
};
</script>

<style scoped>
.link:hover {
  font-weight: bold;
  cursor: url("@/assets/cursor-01-40.png"), auto;
}

.icono-red {
  width: 25px;
  filter: invert(100%) sepia(100%) hue-rotate(180deg); 
}
.icono-red:hover {
  width: 30px;
  filter: invert(100%) sepia(100%) hue-rotate(180deg);
  cursor: url("@/assets/cursor-01-40.png"), auto;
}

.footer {
  background-color: #000000;
  color: #fff;
  padding: 50px 0;
}

.logo {
  width: 95px;
  max-width: 150px;
}

.company-name {
  font-size: 16px;
  margin-top: 10px;
}

.footer h4 {
  font-size: 20px;
  margin-bottom: 15px;
}

.footer-links {
  list-style: none;
  padding: 0;
}

.footer-links li {
  margin-bottom: 10px;
}

.footer-links a {
  color: #fff;
  text-decoration: none;
}

.social-links {
  list-style: none;
  padding: 0;
  margin-top: 20px;
}

.social-links li {
  display: inline-block;
  margin-right: 10px;
}

.social-links a {
  color: #fff;
  font-size: 20px;
  text-decoration: none;
}
</style>
